<template>
  <div>
    <el-form ref="dataForm" :rules="rule" :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">

      <el-form-item label="查询时段">
        <el-date-picker v-model="dataForm.timeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          align="right">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="对话IP">
        <el-input v-model="dataForm.virtualIp" placeholder="对话IP" clearable></el-input>
      </el-form-item>

      <el-form-item label="对话角色">
        <el-input v-model="dataForm.nickname" placeholder="角色名称" clearable></el-input>
      </el-form-item>

      <el-form-item label="聊天类型" prop="queryType">
        <el-select v-model="dataForm.queryType" placeholder="聊天类型">
          <el-option v-for="item in queryTypeOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item>
        <el-button @click="getDataList()" type="primary">搜索</el-button>
        <el-button @click="refresh()">重置</el-button>
        <el-button @click="exportList()" type="info">导出</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%">
      <el-table-column prop="statDate" header-align="center" align="center" label="时间" width="100">
      </el-table-column>

      <el-table-column prop="uid" header-align="center" align="center" label="用户id">
      </el-table-column>
      <el-table-column prop="nickname" header-align="center" align="center" label="用户名称">
      </el-table-column>
      <el-table-column prop="countryCode" header-align="center" align="center" label="国家编号" width="100">
      </el-table-column>
      <el-table-column prop="queryType" header-align="center" align="center" label="聊天类型" width="100">
      </el-table-column>

      <el-table-column prop="groupName" header-align="center" align="center" label="群名称" width="100">
      </el-table-column>

      <el-table-column prop="virtualIp" header-align="center" align="center" label="对话IP">
      </el-table-column>

      <el-table-column prop="virtualNickname" header-align="center" align="center" label="对话角色" width="150">
      </el-table-column>

      <el-table-column prop="talkMode" header-align="center" align="center" label="角色模型" width="150">
      </el-table-column>

      <el-table-column prop="userSendMsgCount" header-align="center" align="center" label="发送信息数量">
      </el-table-column>

      <el-table-column prop="inspirationFlag" header-align="center" align="center" label="是否使用灵感">
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.inspirationRounds == null" type="danger">否</el-tag>
            <el-tag v-else type="success">是</el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="inspirationRounds" header-align="center" align="center" label="使用灵感次数">
        <template slot-scope="scope">
          <div>
            {{ scope.row.inspirationRounds == null ? '/' : scope.row.inspirationRounds }}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="gptTokens" header-align="center" align="center" label="tokens总量">
      </el-table-column>

      <el-table-column prop="groupMemberCount" header-align="center" align="center" label="群人数">
      </el-table-column>

      <el-table-column prop="groupActiveNum" header-align="center" align="center" label="群聊活跃人数">
      </el-table-column>

      <el-table-column prop="groupChatMemberCount" header-align="center" align="center" label="群内发言人数">
      </el-table-column>

    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
      :page-sizes="[5, 10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>

  </div>
</template>

<script>
import { pickerOptions } from "@/views/common/commonOpt";

export default {
  data() {
    return {
      dataForm: {
        nickname: '',
        timeArr: [],
        startTime: '',
        endTime: '',
        virtualIp: '',
        occupation: '',
        queryType: 'c2c',
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      pickerOptions: pickerOptions,
      queryTypeOpt: [
        {
          value: 'c2c',
          label: '私聊IM'
        },
        {
          value: 'group',
          label: '群聊'
        }
      ],
      // 先不用这个
      rule: {
        queryType: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' }
        ],
      }
    }
  },
  components: {
  },
  computed: {
    timeDefault() {
      var date = new Date();
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + (date.getDate());
    }
  },
  mounted() {
    // 初始化查询，默认为当天
    this.dataForm.timeArr = [this.timeDefault + ' 00:00:00', this.timeDefault + ' 23:59:59']
  },
  activated() {
    this.getDataList()
  },
  methods: {
    refresh() {
      this.dataForm.nickname = ''
      this.dataForm.timeArr = []
      this.dataForm.startTime = ''
      this.dataForm.endTime = ''
      this.dataForm.virtualIp = ''
      this.dataForm.occupation = ''
      this.dataForm.queryType = ''
      this.pageIndex = 1
      this.pageSize = 10
      this.getDataList()
    },
    // 获取数据列表
    getDataList() {
      if (!this.dataForm.queryType || this.dataForm.queryType === '') {
        this.$message.error("聊天类型不能为空")
      }
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/chatData/list'),
        timeout: 120000,
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm,
          startTime: (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[0] : null,
          endTime: (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[1] : null,
          page: this.pageIndex,
          limit: this.pageSize,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
          this.totalPage = data.page.totalCount
        } else {
          this.dataList = []
          this.totalPage = 0
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    exportList() {
      const exportData = {
        ...this.dataForm,
        startTime: (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[0] : null,
        endTime: (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[1] : null
      }
      const paramJson = encodeURI(JSON.stringify(exportData).replace(/\+/g, "%2b"))
      window.open(
        this.$http.adornUrl('/admin/chatData/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`,
        "_blank"
      );
    }
  }
}
</script>